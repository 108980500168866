.logo {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__link {
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;

    svg {
      fill: currentColor;
      display: block;
      height: 40px !important; 
      width: auto !important;
    }
  }

  &__link:hover {
    
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;

    svg {
      fill: currentColor;
      display: block;
      height: 40px !important; 
      width: auto !important;
    }
  }

  p {
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: 6px;
    text-align: center;
  }
}
