.preference-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  text-align: start;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  font-style: normal;
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "Roboto",Arial,serif;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  z-index: 500;
  overflow: visible;
  color: #000;
  background-color: #fff;

  .brand-title{
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-weight: 500;
    color: #000;
    box-sizing: border-box;
    font-size: 28px;
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    font-family: Roboto;
    margin: 0 30px;
  }

  .header-menu {
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: "Roboto",Arial,serif;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 20px;
    padding-top: 15px!important;

    .menu-link {
      text-align: var(--bs-body-text-align);
      -webkit-tap-highlight-color: transparent;
      line-height: 1.5;
      font-style: normal;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-family: "Roboto",Arial,serif;
      font-weight: 500;
      box-sizing: border-box;
      text-decoration: none;
      color: #000;
      align-items: center;
      height: 100%;
      padding: 0 20px;
      white-space: nowrap;
      display: flex;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      margin: 0;
      cursor: pointer;
      font-size: 1.1em;

      &:hover{
        position: static;
        text-decoration: underline;
      }
    }

  }

  // mobile menu
  .header-menu-mobile {
    .menu-toggle{
      padding: 0 25px;
      background-color:white !important;
      border-width: 0 !important;

      &:focus{
        border-width: 0 !important;
        box-shadow: none !important;
      }

      &:active{
        border-width: 0 !important;
        box-shadow: none !important;
      }

      &::after{
        border-width: 0 !important;
        box-shadow: none !important;
      }

    }
    .header-menu-mobile-item{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 14px 200px 14px 22px;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
      font-weight: 500;
      font-size: 15px;
      &:hover{
        text-decoration: underline;
      }
    }

  }

}
