html {
	scroll-behavior: smooth;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.5;

  .btn {
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    color: rgb(2, 170, 217);
    background: rgba(54, 4, 19, 0);
    border: 2px solid rgba(2, 170, 217, 0.83);
    border-radius: 6px;
    box-shadow: none;
    padding: 0.63em 0.84em;
    display: flex;
    font-size: 15px;
    margin: 15px;
    white-space: nowrap;
    &.wide {
      flex-grow: 1;
      margin: 0;
    }
    &:hover {
      background-color: rgba(2, 170, 217, 0.83);
      color: white !important;
      text-decoration: none;
    }
  }

  .right-margin {
    margin-right: 5px !important;
  }

  .left-margin {
    margin-left: 5px !important;
  }

  .primary{
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --title-scale: 1;
    --btn-radius: 6px;
    --color-1: rgb(238, 238, 238);
    --color-2: rgb(238, 238, 238);
    --color-1-inv: rgb(0, 0, 0);
    --color-2-inv: rgb(0, 0, 0);
    --color-link: rgb(0, 0, 0);
    --wr-max: 2000px;
    position: relative;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    padding: 0.63em 0.84em;
    color: rgb(255, 255, 255);
    background: rgb(217, 96, 87);
    border: none;
    border-radius: 6px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.14);
    display: flex;
    font-size: 15px;
    white-space: nowrap;
    margin: 0;

    &.additional-left-margin{
      margin: 0 0 0 15px;
    }

    &:hover {
      background: rgb(217, 96, 87);
      box-shadow: 0 4px 5px rgba(0,0,0,.14);
    }
  }

  .profile-page-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .page-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 60%;
      min-width: 350px;
      min-height: 75vh;

      .page-block{

        .caption{
          padding-top: 60px;
          font-style: normal;
          -webkit-text-size-adjust: 100%;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          text-transform: none;
          letter-spacing: 0;
          box-sizing: unset;
          text-align: center;
          line-height: 1.3em;
          font-weight: 600;
          font-family: 'Poppins';
          // margin-top: 20px;
          margin-bottom: 20px;
          color: rgb(0, 0, 0);
          font-size:  calc(12px + 1.8vw);
          .imoji{
            font-size: calc(10px + 1.2vw);
            padding: 0 5px;
          }
        }

        .caption-annotation{
          font-family: 'Poppins';
          font-size: 20px;
          width: 100%;
          text-align: center;
        }

        .sub-caption {
          font-style: normal;
          text-rendering: optimizeLegibility;
          text-transform: none;
          line-height: 1.28581;
          letter-spacing: 0;
          font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          color: rgba(174,175,176,1);
          box-sizing: unset;
          text-align: justify;
          text-justify: auto;
          margin: 0;
          font-size: 1.5em;
          font-weight: 400;
          width: 100%;
          margin-bottom: 2px;
        }
        
        .field-set{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          width: 100%;

          .label{
            padding-top: 10px;
            padding-bottom: 5px;
            white-space: nowrap;
            font-size: 16px;
            display: block;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-weight: 400;
            color: rgb(82, 85, 87)
          }

          input{
            width: 100%;
            color: black;
            font-weight: 500;
            border: unset;
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            margin: 5px 0;
            min-width: 100px;
            height: 40px;
            z-index: 2;
            padding: 0 12px;
            font-size: 1.1em;
            outline: none;
            box-shadow: none;
            -webkit-appearance: none;
            border: 1px solid lightgray;
            border-radius: 6px;
            color:gray;
          }

          .code-block {
            width: calc(120px + 50vw);;
            align-self: center;
            //min-width: 300px;
            border-radius: 6px;
            box-sizing: border-box;
            max-width: 700px;
          }

          .billing-frame-container{
            width: 100%;
            min-width: 350px;
          }
        }
      
      }

      .row-oriented-container{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
        &.wrapped{
          flex-wrap: wrap;
        }
        &.input-mimicry{
          width: 100%;
          color: black;
          font-weight: 500;
          border: unset;
          width: 100%;
          box-sizing: border-box;
          margin: 5px 0;
          min-width: 100px;
          height: 40px;
          z-index: 2;
          padding: 0 12px;
          font-size: 1.1em;
          outline: none;
          box-shadow: none;
          -webkit-appearance: none;
          border: 1px solid lightgray;
          border-radius: 6px;
          color:gray;

          input{
            border: none;
            border-radius: none;
            margin: 0;
            padding: 0;
            height: 34px;
          }
        }
      }
    }
  }

  .icon {
    margin: 0 5px;
    color: gray;
    cursor: pointer;
    font-size: 14px;
    width: 20px;

    .tooltip-custom {
      background-color: gray;
    }
  }

  .red-revoke-notice-area {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    border: 1px solid #e16259;
    color: #e16259;
    padding: 10px 10px;
    margin: 5px 0;
    border-radius: 6px;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;

    &-icon {
      margin-right: 10px;
    }

    &-label {
      margin-right: 10px;
      font-size: 14px;
    }

    &-button {
      color: white;
      background-color: #e16259;
      border: unset;
      border-radius: 4px;
      padding: 8px 16px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
    }
  }

  .logout-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-image: url('../../images/logout-white.png');
    background-size: cover;
    cursor: pointer;

    &.black {
      background-blend-mode: darken
    }
  }
  
  .github-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    background-image: url('../../images/GitHub-Mark-32px.png');
    background-size: cover;
    cursor: pointer;
  }
  
}
