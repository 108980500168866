.login-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;

  .login-header {
    text-align: center;
    margin-bottom: 30px;
  }

  p{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: block;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
    text-align: left;
  }

  .login-form {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: 0;
    padding: 16px;
    display: inline-block;
    text-align-last: left;
    width: 300px;

    input {
      display: block;
      width: 100%;
      box-sizing: border-box;
      background: #F7F6F3;
      border-radius: 6px;
      color: black;
      border: none;
      padding: 10px;
    }

    label{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: block;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
    }

    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: block;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 8px;
      text-align: left;
    }

    button {
      font-style: normal;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      text-transform: none;
      letter-spacing: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      border: none;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
      font-weight: 500;
      outline: none;
      cursor: pointer;
      box-sizing: border-box;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      font-size: 18px;
      color: white;
      background: rgb(217, 96, 87);
      border-radius: 6px;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,.171);
      width: 100%;
      &:hover {
        box-shadow: 0px 3px 4px 0px rgba(0,0,0,.191);
      }
      &:disabled{
        background: rgb(237, 116, 107);
        cursor: no-drop;
        color: #ddd !important;
        &:hover {
          color: #ddd !important;
        }
      }
      span {
        margin: 0.63em 0.84em;
      }
    }

    &__group {
      margin-bottom: 15px;
      text-align: left;
    }

    &__error {
      color: #DC3545;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }
  }
}