.copy2clipboard {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  
    &-link{
      display: flex;
      font-style: normal;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      text-transform: none;
      letter-spacing: 0;
      direction: ltr;
      line-height: 0.2;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
      font-weight: 300;
      cursor: pointer;
      font-size: 14px;
      // color: rgb(2, 170, 217);
      color: gray;
  
      &--done {
        color: green !important;
        line-height: 0.2 !important;
        text-decoration: none;
      }
    }
  }
