.frame-hidden{
    display:none;
}

.label-center{
    margin-top: 30px;
    margin-bottom: 50px;
    white-space: nowrap;
    font-size: 16px;
    display: block;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 400;
    color: rgb(82, 85, 87)
  }