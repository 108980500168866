.footer{
    padding: 15px 15px;
    text-transform: none;
    line-height: 1.28581;
    letter-spacing: 0;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 54px;
    width: 100%;
    margin-top: 50px;


    &__primary {
        font-style: normal;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        text-transform: none;
        line-height: 1.28581;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 400;
        font-family: var(--jp-ui-font-family);
        color: black;
        box-sizing: unset;
        margin-right: 40px;
        margin-bottom: 0;
    }

    &__linkGroups {
        font-style: normal;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        text-transform: none;
        line-height: 1.28581;
        letter-spacing: 0;
        font-size: 14px;
        font-weight: 400;
        color: rgba(174,175,176,1);
        box-sizing: unset;
        display: flex;
        margin: 0 60px;
        text-align: left;
        flex-wrap: wrap;

        li.weight-text {
            font-style: normal;
            -webkit-text-size-adjust: 100%;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            text-transform: none;
            line-height: 1.28581;
            letter-spacing: 0;
            font-size: 14px;
            color: black;
            list-style: none;
            box-sizing: unset;
            font-weight: 400;
            padding-bottom: 6px;
        }

        a {
            font-style: normal;
            -webkit-text-size-adjust: 100%;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            text-transform: none;
            line-height: 1.28581;
            letter-spacing: 0;
            font-size: 14px;
            list-style: none;
            text-align: left;
            box-sizing: unset;
            background-color: transparent;
            font-weight: 500;
            display: block;
            text-decoration: none;
            padding: 4px 0;
            margin-bottom: 3px;
            color: #AAAAAA;
        }
    }
}
