.not-found-page-container {
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin-top: 30px;
        margin-bottom: 50px;
        white-space: nowrap;
        font-size: 20px;
        display: block;
        text-align: right;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 400;
        color: rgb(82, 85, 87);
        text-align: center;
    }
}
